/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useQuery } from 'react-apollo';
import OriginTracing from '@atlassiansox/origin-tracing';

import { COMMERCE_BACKEND_CCP, COMMERCE_BACKEND_HAMS } from '@atlassian/commerce-ui/rollout';

import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	getAGGClient,
	isErrorMarkedAsHandled,
	isGraphQLError,
	markErrorAsHandled,
} from '@confluence/graphql';

import type { CcpEntitlementQuery as CcpEntitlementQueryType } from '../Query/__types__/CcpEntitlementQuery';
import { CcpEntitlementQuery } from '../Query/CcpEntitlementQuery.graphql';
import { getCcpEntitlementFromQuery } from '../Query/getCcpEntitlementFromQuery';

import type { CommerceBackendName } from './useCommerceRolloutInformation';
import { useCommerceRolloutInformation } from './useCommerceRolloutInformation';
import { CcpTransactionAccountIdQuery } from './CcpTransactionAccountIdQuery.agggraphql';

export enum CommerceDeepLinksTargetEdition {
	FREE = 'free',
	STANDARD = 'standard',
	PREMIUM = 'premium',
}

export interface UseCommerceDeepLinksProps {
	targetEdition?: CommerceDeepLinksTargetEdition;
	skipDeepLinks?: boolean;
}

export interface UseCommerceDeepLinksResponse {
	commerceBackendName: CommerceBackendName;
	planSelectionDeepLink?: string;
	planConsentDeepLink?: string;
	subscriptionDetailsDeepLink?: string;
	loading: boolean;
	errorMessage?: string;
}

const CLOUD_ADMIN_ENV_URLS = {
	PRODUCTION: 'https://admin.atlassian.com',
	STAGING: 'https://admin.stg.atlassian.com',
};
export const HAMS_CHANGE_PLAN_DEEPLINK =
	'/admin/billing/applications/change-edition/confluence.ondemand';
export const HAMS_SUBSCRIPTION_DETAILS_DEEPLINK = '/admin/billing/applications';

interface BuildCommerceLinkProps {
	cloudId?: string;
	commerceBackendName?: CommerceBackendName;
	environment?: string;
	entitlementId?: string;
	orgId?: string;
	targetEdition?: CommerceDeepLinksTargetEdition;
	transactionAccountId?: string;
}

const addAtlOriginToUrl = (url: string): string => {
	const origin = new OriginTracing({ product: 'confluence' });
	return origin.addToUrl(url);
};

export const buildFallbackURL = ({
	cloudId,
	environment,
	orgId,
}: Pick<BuildCommerceLinkProps, 'cloudId' | 'environment' | 'orgId'>): string => {
	const CLOUD_ADMIN_URL = CLOUD_ADMIN_ENV_URLS[environment ?? 'PRODUCTION'];
	if (orgId || cloudId) {
		return orgId
			? `${CLOUD_ADMIN_URL}/o/${orgId}/billing-preview`
			: `${CLOUD_ADMIN_URL}/s/${cloudId}/billing-preview`;
	}
	// In an extreme case where we cannot detect cloudId or orgId, we route user to main AdminHub page.
	return CLOUD_ADMIN_URL;
};

const buildPlanSelectionLink = (params: BuildCommerceLinkProps): string => {
	const { cloudId, commerceBackendName, environment, entitlementId, transactionAccountId, orgId } =
		params;

	if (commerceBackendName === COMMERCE_BACKEND_HAMS) {
		return HAMS_CHANGE_PLAN_DEEPLINK;
	}

	if (commerceBackendName === COMMERCE_BACKEND_CCP && entitlementId && transactionAccountId) {
		const CLOUD_ADMIN_URL = CLOUD_ADMIN_ENV_URLS[environment ?? 'PRODUCTION'];
		// https://admin.atlassian.com/billing/:txaId/entitlement/:entitlementId/change-plan?referrer=[referrer]
		// See https://hello.atlassian.net/wiki/spaces/BILLIONS/pages/2652063891/How+to+Deeplink+to+BAC+s+change+plan
		return `${CLOUD_ADMIN_URL}/billing/${transactionAccountId}/entitlement/${entitlementId}/change-plan?referrer=confluence`;
	}

	return buildFallbackURL({ cloudId, environment, orgId });
};

const buildPlanConsentLink = (params: BuildCommerceLinkProps): string => {
	const {
		cloudId,
		commerceBackendName,
		entitlementId,
		environment,
		orgId,
		transactionAccountId,
		targetEdition,
	} = params;

	if (commerceBackendName === COMMERCE_BACKEND_HAMS) {
		return targetEdition
			? `${buildPlanSelectionLink(params)}/${targetEdition}`
			: buildPlanSelectionLink(params);
	}

	if (commerceBackendName === COMMERCE_BACKEND_CCP && entitlementId && transactionAccountId) {
		// https://admin.atlassian.com/billing/:txaId/entitlement/:entitlementId/change-plan?offeringName=[offering name]&referrer=[referrer]
		// See https://hello.atlassian.net/wiki/spaces/BILLIONS/pages/2820329173/How+to+Deeplink+change+plan+consent
		return targetEdition
			? `${buildPlanSelectionLink(params)}&offeringName=${targetEdition}`
			: buildPlanSelectionLink(params);
	}

	return buildFallbackURL({ cloudId, environment, orgId });
};

const buildSubscriptionDetailsLink = (params: BuildCommerceLinkProps): string => {
	const { cloudId, commerceBackendName, environment, entitlementId, orgId, transactionAccountId } =
		params;

	if (commerceBackendName === COMMERCE_BACKEND_HAMS) {
		return HAMS_SUBSCRIPTION_DETAILS_DEEPLINK;
	}

	if (commerceBackendName === COMMERCE_BACKEND_CCP && entitlementId && transactionAccountId) {
		const CLOUD_ADMIN_URL = CLOUD_ADMIN_ENV_URLS[environment ?? 'PRODUCTION'];
		// https://admin.atlassian.com/billing/:txaId/entitlement/:entitlementId
		return `${CLOUD_ADMIN_URL}/billing/${transactionAccountId}/entitlement/${entitlementId}`;
	}

	return buildFallbackURL({ cloudId, environment, orgId });
};

// (Concept is adopted from Kent C. Dodds here:
//  https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript)
const getErrorMessage = (label: string, error: unknown): string => {
	const message = error instanceof Error ? error.message : String(error);
	return `${label}: ${message}`;
};

export const useCommerceDeepLinks = ({
	targetEdition,
	skipDeepLinks = false,
}: UseCommerceDeepLinksProps): UseCommerceDeepLinksResponse => {
	const { environment, cloudId, orgId } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);
	if (!isSiteAdmin || !cloudId) {
		skipDeepLinks = true;
	}

	/**
	 * The best practice advocates for one graphql per component and ideally no graphql quries in a hook
	 * but to construct the deeplink, we need to:
	 * 1. first call Commerce API to retrieve the Confluence instance backend service (CCP or HAMS),
	 * 2. for CCP, call ccgraphql to retrieve the ccpEntitlementId
	 * 3. for CCP, call AGG to retrieve the CCP transaction account Id using the ccpEntitlementId retrieved in step #2.
	 *
	 * This is a stop gap solution while we work with Commerce to eventually have one AGG query
	 * to provide everything we need with just a CloudId and the product key (e.g. confluence
	 */
	const {
		commerceBackendName,
		loading: commerceBackendNameLoading,
		error: commerceBackendNameError,
	} = useCommerceRolloutInformation(skipDeepLinks);
	const isCcpBackend = commerceBackendName === COMMERCE_BACKEND_CCP;

	const {
		data: ccpEntitlementIdData,
		loading: ccpEntitlementIdLoading,
		error: ccpEntitlementIdError,
	} = useQuery<CcpEntitlementQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CcpEntitlementQuery,
		{
			skip: skipDeepLinks || !isCcpBackend,
		},
	);
	const ccpEntitlementId = !ccpEntitlementIdLoading
		? getCcpEntitlementFromQuery(ccpEntitlementIdData)
		: undefined;

	const {
		data: ccpEntitlementTxaIdData,
		loading: ccpEntitlementTxaIdLoading,
		error: ccpEntitlementTxaIdError,
	} = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CcpTransactionAccountIdQuery,
		{
			variables: { entitlementId: ccpEntitlementId },
			skip: skipDeepLinks || !isCcpBackend || !ccpEntitlementId,
			client: getAGGClient(),
		},
	);

	if (skipDeepLinks) {
		return {
			commerceBackendName: null,
			planSelectionDeepLink: undefined,
			planConsentDeepLink: undefined,
			subscriptionDetailsDeepLink: undefined,
			loading: false,
			errorMessage: undefined,
		};
	}

	let errorMessage: string | undefined;
	let planSelectionDeepLink: string | undefined;
	let planConsentDeepLink: string | undefined;
	let subscriptionDetailsDeepLink: string | undefined;
	try {
		const entitlementId = ccpEntitlementId;
		const transactionAccountId = ccpEntitlementTxaIdData?.ccp?.entitlement?.transactionAccountId;

		const commerceParams: BuildCommerceLinkProps = {
			cloudId,
			commerceBackendName,
			environment,
			entitlementId,
			orgId,
			transactionAccountId,
		};

		planSelectionDeepLink = addAtlOriginToUrl(buildPlanSelectionLink(commerceParams));
		planConsentDeepLink = addAtlOriginToUrl(
			buildPlanConsentLink({
				targetEdition,
				...commerceParams,
			}),
		);
		subscriptionDetailsDeepLink = addAtlOriginToUrl(buildSubscriptionDetailsLink(commerceParams));

		// Mark all graphql errors as handled since we will return the fallback plan selection url
		if (isGraphQLError(ccpEntitlementIdError) && !isErrorMarkedAsHandled(ccpEntitlementIdError)) {
			markErrorAsHandled(ccpEntitlementIdError);
		}
		if (
			isGraphQLError(ccpEntitlementTxaIdError) &&
			!isErrorMarkedAsHandled(ccpEntitlementTxaIdError)
		) {
			markErrorAsHandled(ccpEntitlementTxaIdError);
		}

		if (commerceBackendNameError) {
			errorMessage = getErrorMessage(
				'Error retrieving Commerce Backend Service',
				commerceBackendNameError,
			);
		} else if (ccpEntitlementIdError) {
			errorMessage = getErrorMessage('Error retrieving CCP Entitlement Id', ccpEntitlementIdError);
		} else if (ccpEntitlementTxaIdError) {
			errorMessage = getErrorMessage(
				'Error retrieving CCP Transaction Account Id',
				ccpEntitlementTxaIdError,
			);
		}
	} catch (e) {
		errorMessage = getErrorMessage('Error in useCommerceDeepLinks', e);
	}

	const loading =
		commerceBackendNameLoading || ccpEntitlementIdLoading || ccpEntitlementTxaIdLoading;

	return {
		commerceBackendName,
		planSelectionDeepLink,
		planConsentDeepLink,
		subscriptionDetailsDeepLink,
		loading,
		errorMessage,
	};
};
